<template>
  <div class="look">
    <div class="look-content">
      <div class="look-left">
        <img class="look-left__img" :src="previewImg.coverUrl" />
        <div class="tools">
          <template v-if="isSelected(previewImg.coverUrl)">
            <el-input type="textarea" autosize class="tools__textarea" placeholder="请输入修片要求" :value="previewRemark" @input="previewRemarkInput"></el-input>
            <el-button @click="onUnSelected">已 选</el-button>
          </template>
          <el-button v-if="!isSelected(previewImg.coverUrl)" type="primary" @click="onSelected">选 中</el-button>
        </div>
      </div>
      <div class="look-right">
        <el-tabs v-if="statusType && !loading" :before-leave="() => false">
          <el-tab-pane :label="statusType.label + (dataList.length ? `(${dataList.length})` : '')" name="first"></el-tab-pane>
        </el-tabs>
        <div class="calculate">
          <div class="calculate-left">
            <span>已选：{{ selected.length }}张</span>
            <span>可选：{{ maxCanSelected }}张</span>
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              下拉菜单
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>黄金糕</el-dropdown-item>
              <el-dropdown-item>狮子头</el-dropdown-item>
              <el-dropdown-item>螺蛳粉</el-dropdown-item>
              <el-dropdown-item disabled>双皮奶</el-dropdown-item>
              <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="body">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="body-item"
            :class="{
              'body-item--active': previewImg.coverUrl === item.coverUrl,
              'body-item--checked': isSelected(item.coverUrl),
            }"
            @click="onPreview(item)"
          >
            <el-image style="width: 100px; height: 100px" :src="item.coverUrl" fit="cover"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="look-footer"></div>
  </div>
</template>

<script>
import { getServiceById, getViewSampleProductList, getViewFileList, getViewLayoutList, getViewPhotosSelectList } from '@/api/order.js'
export default {
  data() {
    return {
      serviceId: '',
      type: 0,
      dataList: [],
      loading: true,
      maxCanSelected: 0,
      selected: [],
      previewImg: {},
    }
  },
  computed: {
    statusType() {
      if (Number(this.type) === 0) return { label: '选片', type: 0 }
      if (Number(this.type) === 1) return { label: '选版', type: 1 }
      if (Number(this.type) === 2) return { label: '制作', type: 2 }
      return null
    },
    previewRemark() {
      return this.selected.find((e) => e.coverUrl === this.previewImg.coverUrl).remark
    },
  },
  created() {
    this.serviceId = this.$route.query.serviceId
    this.getService()
  },
  methods: {
    async getService() {
      const res = await getServiceById({
        data: this.serviceId,
      })
      console.log(res)
      this.handleStatus(res.data.subStatus)
      this.getViewSampleProduct()
      this.loading = true
      if (Number(this.type) === 0) this.dataList = await this.chipSelectList()
      if (Number(this.type) === 1) this.dataList = await this.formatList()
      if (Number(this.type) === 2) this.dataList = await this.makeList()
      this.dataList = this.dataList.map((e) => {
        e.coverUrl = e.coverUrl ? e.coverUrl : e.url
        return e
      })
      this.loading = false
      this.previewImg = this.dataList[0]
    },
    handleStatus(status) {
      status = Number(status)
      if (status === 11 || status === 13 || status === 15) this.type = 0
      if (status === 21) this.type = 1
      if (status === 24) this.type = 2
    },
    getViewSampleProduct() {
      getViewSampleProductList({
        data: this.serviceId,
      }).then((res) => {
        const key = ['truingList', 'desingList', 'mountList']
        if (res.data && res.data[key[this.type]]) {
          const list = res.data[key[this.type]]
          this.maxCanSelected = list.reduce((pre, cur) => {
            return pre + cur.number
          }, 0)
        }
      })
    },
    async chipSelectList() {
      try {
        const res = await getViewFileList({
          data: this.serviceId,
        })
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    async formatList() {
      try {
        const res = await getViewLayoutList({
          data: this.serviceId,
        })
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    async makeList() {
      try {
        const res = await getViewPhotosSelectList({
          data: this.serviceId,
        })
        return res.data
      } catch (error) {
        console.log(error)
      }
    },
    onPreview(e) {
      this.previewImg = e
    },
    onSelected() {
      this.selected.push({ ...this.previewImg, remark: '' })
      console.log(this.selected)
    },
    onUnSelected() {
      this.selected = this.selected.filter((e) => e.coverUrl !== this.previewImg.coverUrl)
    },
    isSelected(url) {
      return this.selected.some((e) => e.coverUrl === url)
    },

    previewRemarkInput(e) {
      console.log(e)
      this.selected = this.selected.map((el) => {
        if (el.coverUrl === this.previewImg.coverUrl) {
          el.remark = e
        }
        return el
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.look {
  width: 100%;
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  &-content {
    display: flex;
    width: 100%;
    flex: 1;
    overflow: hidden;
  }
  &-footer {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #ededed;
  }
  &-left {
    flex: 1;
    overflow: hidden;
    background-color: #f7f7f7;
    position: relative;
    &__img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }
  &-right {
    width: 420px;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}
.calculate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    & > span:nth-child(1) {
      &::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: #c0c4cc;
        margin: 0 16px;
      }
    }
  }
}
.body {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-top: 25px;
  &-item {
    width: 114px;
    height: 114px;
    border-radius: 4px;
    margin-right: 16px;
    margin-bottom: 16px;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &--active {
      border: 1px solid #409eff;
    }
    &--checked {
      background-color: rgb(198, 226, 255);
    }
  }
}
.tools {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 60px;
  background: rgba(232, 232, 232, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  &__textarea {
    ::v-deep .el-textarea__inner {
      border: none;
      background-color: transparent !important;
      outline: none;
      flex: 1;
      overflow: hidden;
      margin-right: 40px;
      resize: none;
      font-size: 14px;
    }
  }
}
</style>
